import React, {Component} from 'react';
import {Route} from 'react-router-dom';

import * as Helpers from '../helpers';
import NotFound from '../routes/NotFound';
import PAGES_URL from '../routes/pageRoutes';

class PrivateRoutes extends Component {
  render() {
    return Helpers.token.get('admin:token') ? (
      <Route {...this.props} />
    ) : (
      <Route path={PAGES_URL.LOGIN} element={<NotFound />} />
    );
  }
}

export default PrivateRoutes;
