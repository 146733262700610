import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react';

export default function Editor3({bodyValue, onChange}) {
  function uploadAdapter(loader) {
    return {
      upload: () =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          loader.file.then(file => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const base64Data = `data:${file.type};base64,${
                reader.result.split(',')[1]
              }`;
              fetch(`${process.env.REACT_APP_BASE_URL}/blog/upload`, {
                method: 'POST',
                body: JSON.stringify({image: base64Data}),
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                .then(response => response.json())
                .then(result => {
                  resolve({default: result.image});
                })
                .catch(error => {
                  reject(error);
                });
            };
            reader.onerror = error => reject(error);
          });
        }),
    };
  }

  function uploadPlugin(editor) {
    // eslint-disable-next-line no-param-reassign
    editor.plugins.get('FileRepository').createUploadAdapter = loader =>
      uploadAdapter(loader);
  }

  return (
    <CKEditor
      editor={Editor}
      data={bodyValue || ''}
      onChange={onChange}
      config={{
        extraPlugins: [uploadPlugin],
      }}
    />
  );
}
