import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {avatar} from '../assets/images/images';
import * as Actions from '../store/actions';
import Sidebar from './Sidebar';
import Modal from './Modal';

function useMedia(query) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

function Header({headerText = '', auth}) {
  const {role, first_name, last_name} = auth?.user;
  const [open, setOpen] = useState(false);
  const x = useMedia('(min-width: 1024px)');

  useEffect(() => {
    if (x) {
      setOpen(false);
    }
  }, [x]);

  return (
    <header className="sticky top-0 right-0 left-auto z-10 text-white bg-white">
      <div className="flex relative justify-between items-center px-5 h-16 border-b border-borderGrey lg:pr-[104px] lg:pl-6 xl:pl-14">
        <span className="text-lg font-bold text-grey100">{headerText}</span>
        <div className="flex items-center justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={() => setOpen(!open)}
            className="lg:hidden"
          >
            <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
            <Modal
              show={open}
              onClose={setOpen}
              extraModalClasses="sidebar-mobile"
            >
              <Sidebar />
            </Modal>
          </svg>
          <div className="items-center hidden gap-6 lg:flex">
            <button className="relative block w-8 h-8 overflow-hidden rounded-full focus:outline-none">
              <img
                className="object-cover w-full h-full"
                src={avatar}
                alt="avatar"
              />
            </button>
            <div className="flex flex-col">
              <p className="text-sm text-grey100">{`${first_name} ${last_name}`}</p>
              <p className="text-xs font-bold uppercase text-navGrey">
                {Array.isArray(role) ? role.join(', ') : role}
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default connect(state => state, Actions)(Header);
