export const formatNumberToCurrency = (number, currency, rate) => {
  const convertStringToNumber =
    typeof number === 'string' ? Number(number) : number;

  const getCountryLanguageByCurrencySymbol = () => {
    switch (currency) {
      case 'NGN':
      case 'USD':
        return 'en';
      case 'AOA':
        return 'pt';

      default:
        return 'NGN';
    }
  };

  if (currency && currency !== 'NGN') {
    const convertedNumber = rate
      ? convertStringToNumber / rate
      : convertStringToNumber;
    return new Intl.NumberFormat(
      `${getCountryLanguageByCurrencySymbol()}-${currency?.substr(0, 2)}`,
      {
        currency,
        style: 'currency',
      },
    ).format(convertedNumber || 0);
  }

  return new Intl.NumberFormat('en-NG', {
    currency: 'NGN',
    style: 'currency',
  }).format(Math.round(convertStringToNumber) || 0);
};
