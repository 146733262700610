const DASHBOARD = '/dashboard';

// Authentication routes
const LOGIN = '/auth';
const LOGIN_SUCCESS = '/';

// Super Admin
const SUPER_ADMIN_SETTINGS = '/super-admin-settings';

const COMPANY = '/company';
const COMPANY_INFO = '/company/:id';
const COMPANY_SERVICES = '/company/:id/services';
const COMPANY_USERS = '/company/:id/users';
const COMPANY_APPS = '/company/:id/apps';
const COMPANY_API_LOGS = '/company/:id/api-logs';
const COMPANY_BILLINGS = '/company/:id/billings';
const COMPANY_INDIVIDUAL_EASY_LOOKUP = '/company/:id/individual-easy-lookup';
const COMPANY_BATCH_EASY_LOOKUP = '/company/:id/batch-easy-lookup';
const COMPANY_WIDGET = '/company/:id/widget';
const COMPANY_COMPLIANCE = '/company/:id/compliance';
const COMPANY_SETTINGS = '/company/:id/settings';
const COMPANY_AUDIT_TRAIL = '/company/:id/audit-trail';
const COMPANY_TEAMS = '/company/:id/teams';
const USERS = '/users';
const RECONCILO_USERS = '/reconcilo/users';
const RECONCILO_COMPANY = '/reconcilo/company';
const USERS_PROFILE = '/users/profile';
const API_LOGS = '/api-logs';
const BILLINGS = '/billings';
const MANAGE_SENDER_IDS = '/manage-senders-ids';
const ADMINS = '/admins';
const AUDIT_TRAIL = '/audit-trail';
const BLOG = '/blog';
const ADD_BLOG = '/blog/add';
const EDIT_BLOG = '/blog/:id';
const BLOG_CATEGORIES = '/blog/categories';
const PROFILE = '/profile';
const VERIFICATIONS = '/verifications';
const PENDING_VERIFICATIONS = '/verifications/pending';
const VERIFICATIONSBIODATA = '/verifications/bio-data/:slug';
const VERIFICATIONSADDRESS = '/verifications/address/:slug';
const VERIFICATIONSAMLCHECK = '/verifications/aml-check/:slug';
const VERIFICATIONSIPDEVICECHECK = '/verifications/ip-device-check/:slug';

// const VERIFY = "/verify";
const ERROR404 = '/not-found';

const PAGES_URL = {
  ADD_BLOG,
  EDIT_BLOG,
  ADMINS,
  API_LOGS,
  AUDIT_TRAIL,
  BILLINGS,
  BLOG,
  BLOG_CATEGORIES,
  COMPANY,
  COMPANY_API_LOGS,
  COMPANY_APPS,
  COMPANY_AUDIT_TRAIL,
  COMPANY_BATCH_EASY_LOOKUP,
  COMPANY_BILLINGS,
  COMPANY_INDIVIDUAL_EASY_LOOKUP,
  COMPANY_INFO,
  COMPANY_SERVICES,
  COMPANY_USERS,
  COMPANY_SETTINGS,
  COMPANY_WIDGET,
  COMPANY_COMPLIANCE,
  COMPANY_TEAMS,
  DASHBOARD,
  ERROR404,
  // Authentication routes
  LOGIN,

  LOGIN_SUCCESS,

  MANAGE_SENDER_IDS,

  USERS,

  USERS_PROFILE,

  // SUPER ADMIN
  SUPER_ADMIN_SETTINGS,
  PROFILE,
  VERIFICATIONS,
  PENDING_VERIFICATIONS,
  VERIFICATIONSBIODATA,
  VERIFICATIONSADDRESS,
  VERIFICATIONSAMLCHECK,
  VERIFICATIONSIPDEVICECHECK,
  RECONCILO_USERS,
  RECONCILO_COMPANY,
};

export default PAGES_URL;
