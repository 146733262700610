// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/dropdown-grey.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter .react-datepicker__input-container > input {
  font-size: 10px !important;
  font-weight: 500 !important;
  outline: none !important;
  height: 32px !important;
  cursor: pointer !important;
  width: 100% !important;
  background-color: #f9f9f9;
  padding-left: 8px !important;
}

.react-datepicker {
  font-family: Atakk, sans-serif !important;
}

.react-datepicker__year-option .react-datepicker__navigation--years-previous {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  border: none;
  inset: 0 !important;
  width: 16px;
  height: 16px;
}

.react-datepicker__year-option .react-datepicker__navigation--years-upcoming {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  border: none;
  transform: rotate(180deg);
  inset: 0 !important;
  width: 16px;
  height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/datepicker.css"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,2BAAA;EACA,wBAAA;EACA,uBAAA;EACA,0BAAA;EACA,sBAAA;EACA,yBAAA;EACA,4BAAA;AACF;;AAEA;EACE,yCAAA;AACF;;AAEA;EACE,6DAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,6DAAA;EACA,YAAA;EACA,yBAAA;EAEA,mBAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".filter .react-datepicker__input-container > input {\n  font-size: 10px !important;\n  font-weight: 500 !important;\n  outline: none !important;\n  height: 32px !important;\n  cursor: pointer !important;\n  width: 100% !important;\n  background-color: #f9f9f9;\n  padding-left: 8px !important;\n}\n\n.react-datepicker {\n  font-family: Atakk, sans-serif !important;\n}\n\n.react-datepicker__year-option .react-datepicker__navigation--years-previous {\n  background: url(../images/dropdown-grey.svg) no-repeat;\n  border: none;\n  inset: 0 !important;\n  width: 16px;\n  height: 16px;\n}\n\n.react-datepicker__year-option .react-datepicker__navigation--years-upcoming {\n  background: url(../images/dropdown-grey.svg) no-repeat;\n  border: none;\n  transform: rotate(180deg);\n  inset: 0 !important;\n  inset: 0 !important;\n  width: 16px;\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
