// PNGs
export {default as logo} from './logo.png';
export {default as avatar} from './avatar.png';
export {default as favicon} from './favicon.png';
export {default as loginBg} from './login-bg.png';
export {default as publish} from './publish.png';
export {default as placeholder} from './placeholder.png';
export {default as reactivate} from './resend.png';
export {default as empty} from './empty.png';
export {default as check_export} from './check.png';
export {default as resend} from './direct-send.png';
export {default as slaSignee} from './sla-signee-placeholder.png';
export {default as governmentDataSelfie} from './government-data-selfie.png';
export {default as governmentId} from './government-id.png';
export {default as map} from './map.png';

// SVGs
export {default as adminsNav} from './admins-nav.svg';
export {default as success_svg} from './success.svg';
export {default as apiLogsNav} from './api-logs-nav.svg';
export {default as mark} from './mark.svg';
export {default as white_marker} from './white-marker.svg';
export {default as billingsNav} from './billings-nav.svg';
export {default as blogNav} from './blog-nav.svg';
export {default as companyNav} from './company-nav.svg';
export {default as dashboardNav} from './dashboard-nav.svg';
export {default as senderIdNav} from './sender-id-nav.svg';
export {default as usersNav} from './users-nav.svg';
export {default as logOut} from './log-out.svg';
export {default as usersIcon} from './users-icon.svg';
export {default as companies} from './companies.svg';
export {default as totalRevenue} from './total-revenue.svg';
export {default as apiLogs} from './api-logs.svg';
export {default as totalCompanies} from './total-companies.svg';
export {default as verifiedCompanies} from './verified-companies.svg';
export {default as approved} from './approved.svg';
export {default as failed} from './failed.svg';
export {default as unverifiedCompanies} from './unverified-companies.svg';
export {default as alertCircle} from './alert-circle.svg';
export {default as creditCard} from './credit-card.svg';
export {default as userCheck} from './user-check.svg';
export {default as blog} from './blog.svg';
export {default as moreDetails} from './more-details.svg';
export {default as alertCircleGrey} from './alert-circle-grey.svg';
export {default as dropdownGrey} from './dropdown-grey.svg';
export {default as dropdown} from './dropdown.svg';
export {default as dropdownWhite} from './dropdown-white.svg';
export {default as dropdownBlue} from './dropdown-blue.svg';
export {default as calendar} from './calendar.svg';
export {default as search} from './search.svg';
export {default as eye} from './eye.svg';
export {default as hiddenEye} from './hidden-eye.svg';
export {default as approve} from './approve.svg';
export {default as verify} from './verify.svg';
export {default as suspend} from './suspend.svg';
export {default as edit} from './edit.svg';
export {default as trash} from './trash.svg';
export {default as featuredBlogImg} from './featured-blog-img.svg';
export {default as back} from './back.svg';
export {default as changeRole} from './change-role.svg';
export {default as googleIcon} from './google-icon.svg';
export {default as filterCheck} from './filter-check.svg';
export {default as filterNoCheck} from './filter-nocheck.svg';
export {default as blueLine} from './blue-line.svg';
export {default as notFoundImg} from './404-error.svg';
export {default as verifiedBadge} from './verified-badge.svg';
export {default as filterIc} from './filter-ic.svg';
export {default as filterWhite} from './filter-ic-white.svg';
export {default as activityGreen} from './activity-green.svg';
export {default as activityBlue} from './activity-blue.svg';
export {default as activityRed} from './activity-red.svg';
export {default as unpublish} from './unpublish.svg';
export {default as dropdownFillGrey} from './dropdown-fill-grey.svg';
export {default as removeIc} from './remove-ic.svg';
export {default as closeModal} from './close-modal.svg';
export {default as enlargeIc} from './enlarge-ic.svg';
export {default as pinnedIc} from './pinned.svg';
export {default as unpin} from './unpin.svg';
export {default as pin} from './pin.svg';
export {default as removeService} from './remove-service.svg';
export {default as checkedBox} from './checked-box.svg';
export {default as uncheckedBox} from './unchecked-box.svg';
export {default as pending} from './pending.svg';
export {default as corruptedFile} from './corrupted-file.svg';
export {default as biodataIc} from './biodata-ic.svg';
export {default as addressIc} from './address-ic.svg';
export {default as ipDeviceCheckIc} from './ip-device-check-ic.svg';
export {default as amlCheckIc} from './aml-check-ic.svg';
export {default as chevronRight} from './chevron-right.svg';
export {default as chevronRightSm} from './chevron-right-sm.svg';
export {default as downloadGrey} from './download-grey.svg';
export {default as false_address_marker} from './false_match_address.svg';
export {default as true_address_marker} from './success_match_address.svg';
export {default as base_address_marker} from './base_address.svg';
export {default as backNofill} from './back-nofill.svg';
export {default as backWhitefill} from './back-whitefill.svg';
export {default as smiley} from './smiley.svg';
export {default as user} from './user-add-line.svg';
