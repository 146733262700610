import React from 'react';
import {
  Dashboard,
  Users,
  Company,
  CompanyApiLogs,
  Admins,
  ApiLogs,
  CompanyInformation,
  CompanyServices,
  CompanyUsers,
  CompanyApps,
  CompanyBillings,
  CompanyIndividualEasyLookup,
  CompanyBatchEasyLookup,
  CompanyWidget,
  CompanyCompliance,
  CompanySettings,
  CompanyAuditTrail,
  UsersProfile,
  Billings,
  ManageSendersIds,
  AuditTrail,
  BlogCategories,
  Profile,
} from './Dashboard';
import Settings from './SuperAdmin/Settings';
import Blog from './Blog';
import AddBlog from './Blog/AddBlog';
import EditBlog from './Blog/EditBlog';
import PAGES_URL from './pageRoutes';
import Login from './Login';
import LoginSuccess from './LoginSuccess';
import CompanyTeams from './Dashboard/CompanyTeams';
import Verifications from './Dashboard/Verifications';
import Biodata from './Dashboard/Verifications/Biodata';
import IpDeviceCheck from './Dashboard/Verifications/IpDeviceCheck';
import Address from './Dashboard/Verifications/Address';
import AmlCheck from './Dashboard/Verifications/AmlCheck';
import PendingVerifications from './Dashboard/Verifications/PendingVerifications';
import ReconciloUsers from './Dashboard/ReconciloUsers';
import ReconciloCompany from './Dashboard/ReconciloCompany';

const baseRoutes = {
  private: [
    {
      component: <AddBlog />,
      exact: true,
      path: PAGES_URL.ADD_BLOG,
    },
    {
      component: <EditBlog />,
      exact: true,
      path: PAGES_URL.EDIT_BLOG,
    },
    {
      component: <Settings />,
      exact: true,
      path: PAGES_URL.SUPER_ADMIN_SETTINGS,
    },
    {
      component: <Blog />,
      exact: true,
      path: PAGES_URL.BLOG,
    },
    {
      component: <BlogCategories />,
      exact: true,
      path: PAGES_URL.BLOG_CATEGORIES,
    },
    {
      component: <Dashboard />,
      exact: true,
      path: PAGES_URL.DASHBOARD,
    },
    {
      component: <ApiLogs />,
      exact: true,
      path: PAGES_URL.API_LOGS,
    },
    {
      component: <Billings />,
      exact: true,
      path: PAGES_URL.BILLINGS,
    },
    {
      component: <ManageSendersIds />,
      exact: true,
      path: PAGES_URL.MANAGE_SENDER_IDS,
    },
    {
      component: <AuditTrail />,
      exact: true,
      path: PAGES_URL.AUDIT_TRAIL,
    },
    {
      component: <Users />,
      exact: true,
      path: PAGES_URL.USERS,
    },
    {
      component: <ReconciloUsers />,
      exact: true,
      path: PAGES_URL.RECONCILO_USERS,
    },
    {
      component: <ReconciloCompany />,
      exact: true,
      path: PAGES_URL.RECONCILO_COMPANY,
    },
    {
      component: <UsersProfile />,
      exact: true,
      path: PAGES_URL.USERS_PROFILE,
    },
    {
      component: <Admins />,
      exact: true,
      path: PAGES_URL.ADMINS,
    },
    {
      component: <Company />,
      exact: true,
      path: PAGES_URL.COMPANY,
    },
    {
      component: <CompanyInformation />,
      exact: true,
      path: PAGES_URL.COMPANY_INFO,
    },
    {
      component: <CompanyServices />,
      exact: true,
      path: PAGES_URL.COMPANY_SERVICES,
    },
    {
      component: <CompanyUsers />,
      exact: true,
      path: PAGES_URL.COMPANY_USERS,
    },
    {
      component: <CompanyApps />,
      exact: true,
      path: PAGES_URL.COMPANY_APPS,
    },
    {
      component: <CompanyBillings />,
      exact: true,
      path: PAGES_URL.COMPANY_BILLINGS,
    },
    {
      component: <CompanyIndividualEasyLookup />,
      exact: true,
      path: PAGES_URL.COMPANY_INDIVIDUAL_EASY_LOOKUP,
    },
    {
      component: <CompanyBatchEasyLookup />,
      exact: true,
      path: PAGES_URL.COMPANY_BATCH_EASY_LOOKUP,
    },
    {
      component: <CompanyWidget />,
      exact: true,
      path: PAGES_URL.COMPANY_WIDGET,
    },
    {
      component: <CompanyCompliance />,
      exact: true,
      path: PAGES_URL.COMPANY_COMPLIANCE,
    },
    {
      component: <CompanySettings />,
      exact: true,
      path: PAGES_URL.COMPANY_SETTINGS,
    },
    {
      component: <CompanyAuditTrail />,
      exact: true,
      path: PAGES_URL.COMPANY_AUDIT_TRAIL,
    },
    {
      component: <CompanyApiLogs />,
      exact: true,
      path: PAGES_URL.COMPANY_API_LOGS,
    },
    {
      component: <CompanyTeams />,
      exact: true,
      path: PAGES_URL.COMPANY_TEAMS,
    },
    {
      component: <Profile />,
      exact: true,
      path: PAGES_URL.PROFILE,
    },
    {
      component: <Verifications />,
      exact: true,
      path: PAGES_URL.VERIFICATIONS,
    },
    {
      component: <PendingVerifications />,
      exact: true,
      path: PAGES_URL.PENDING_VERIFICATIONS,
    },
    {
      component: <Biodata />,
      exact: true,
      path: PAGES_URL.VERIFICATIONSBIODATA,
    },
    {
      component: <IpDeviceCheck />,
      exact: true,
      path: PAGES_URL.VERIFICATIONSIPDEVICECHECK,
    },
    {
      component: <Address />,
      exact: true,
      path: PAGES_URL.VERIFICATIONSADDRESS,
    },
    {
      component: <AmlCheck />,
      exact: true,
      path: PAGES_URL.VERIFICATIONSAMLCHECK,
    },
  ],
  public: [
    {
      component: <Login />,
      exact: true,
      path: PAGES_URL.LOGIN,
    },
    {
      component: <LoginSuccess />,
      exact: true,
      path: PAGES_URL.LOGIN_SUCCESS,
    },
  ],
};

export default baseRoutes;
