/* eslint-disable no-param-reassign */
import axios from 'axios';
import token from '../token';

const api = axios.create({baseURL: process.env.REACT_APP_BASE_URL});

api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${token?.get('admin:token')}`;
  config.headers['Content-Type'] = 'application/json';
  return config;
});

export default api;
