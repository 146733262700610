import {DashboardIcon} from './DashboardIcon';
import {CompanyIcon} from './CompanyIcon';
import {UsersIcon} from './UsersIcon';
import {ApiLogsIcon} from './ApiLogsIcon';
import {BillingsIcon} from './BillingsIcon';
import {ManageSenderIdsIcon} from './ManageSenderIdsIcon';
import {AdminsIcon} from './AdminsIcon';
import {AuditTrailIcon} from './AuditTrailIcon';
import {ProfileIcon} from './ProfileIcon';
import {BlogIcon} from './BlogIcon';

export {
  DashboardIcon,
  CompanyIcon,
  UsersIcon,
  ApiLogsIcon,
  BillingsIcon,
  ManageSenderIdsIcon,
  AdminsIcon,
  AuditTrailIcon,
  ProfileIcon,
  BlogIcon,
};
