import React, {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import PrimaryButton from '../../components/PrimaryButton';
import {
  getCompanyInfo,
  updateCompanyConfig,
  updateCompanyInfo,
} from '../../helpers/queryFns/fetchCompanyDetails';
import CompanyLayout from './CompanyLayout';
import {MainSettings} from './CompanySettingsComponents/MainSettings';
import {DashboardModularization} from './CompanySettingsComponents/DashboardModularization';
import ThemeConfig from './CompanySettingsComponents/ThemeConfig';
import {SubDomainConfig} from './CompanySettingsComponents/SubDomainConfig';
import {capitalizeEveryWord} from '../../helpers';
import {servicesList} from './dashboardServices';

function CompanySettings() {
  const {id} = useParams();
  const queryClient = useQueryClient();

  const {data, isLoading} = useQuery(['companyInfo-query', id], () =>
    getCompanyInfo(id),
  );

  const [settings, setSettings] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      const {
        sms_route,
        currency,
        white_label,
        color_code,
        logo,
        subdomain,
        is_partner_company,
        parent_company,
        tagline,
        favicon,
        configuration,
        modularisation,
        ingest_url,
        sandbox_url,
        live_url,
        widget_url,
        ...services
      } = data?.company ?? {};

      setSettings(prev => ({
        ...prev,
        smsRoute: {
          sms_route: capitalizeEveryWord(sms_route?.replaceAll('_', ' ')),
          value: sms_route,
        },
        curr: {currency},
        whiteLabel: white_label,
        companyPrimaryColor: color_code,
        imageUrl: logo,
        favicon,
        tagline,
        ingest_url,
        sandbox_url,
        live_url,
        widget_url,
        ...(configuration?.sender_name && {
          sender_name: configuration?.sender_name,
        }),
        ...(configuration?.sender_email && {
          sender_email: configuration?.sender_email,
        }),
        subDomain: subdomain,
        partnerCompany: is_partner_company,
        parentCompany: parent_company,
        ...(configuration?.smtp && {smtp: {...configuration?.smtp}}),
        ...services,
        ...servicesList.reduce((acc, service) => {
          acc[service?.key] = modularisation[service?.key];
          return acc;
        }, {}),
      }));
    }
  }, [data?.company, isLoading]);

  const {mutateAsync: updateConfig, isLoading: updatingCompanyConfig} =
    useMutation(obj => updateCompanyConfig(id, obj), {
      onSuccess: () => {
        queryClient.invalidateQueries(['companyInfo-query', id]);
      },
    });

  const {mutateAsync: updateCompany, isLoading: updatingCompanyInfo} =
    useMutation(obj => updateCompanyInfo(id, obj), {
      onSuccess: () => {
        queryClient.invalidateQueries(['companyInfo-query', id]);
      },
    });

  const buildMutationData = () => ({
    ...(settings?.smsRoute?.sms_route && {
      sms_route: settings?.smsRoute?.sms_route,
    }),
    ...(settings?.companyPrimaryColor && {
      color_code: settings?.companyPrimaryColor,
    }),
    currency: settings?.curr?.currency,
    white_label: settings?.whiteLabel,
    logo: settings?.imageUrl || data?.company?.logo,
    ...(settings?.subDomain
      ? {subdomain: settings?.subDomain}
      : {subdomain: null}),
    ingest_url: settings?.ingest_url,
    sandbox_url: settings?.sandbox_url,
    live_url: settings?.live_url,
    ...(settings?.widget_url === ''
      ? {widget_url: null}
      : {widget_url: settings?.widget_url}),
    is_partner_company: settings?.partnerCompany || false,
    parent_company: settings?.parentCompany?.value,
    ...(settings?.tagline === ''
      ? {tagline: null}
      : {tagline: settings?.tagline}),
    favicon: settings?.favicon,
    modularisation: {
      ...servicesList.reduce(
        (acc, service) => {
          acc[service?.key] = settings[service?.key] || false;
          const {fraud_decision_services, ...rest} = acc;
          return {...rest};
        },
        {...data?.company?.modularisation},
      ),
    },
  });

  const handleSaveChanges = async () => {
    if (!(Boolean(updatingCompanyInfo) || isUploading)) {
      try {
        await Promise.all([
          updateCompany(buildMutationData()),
          settings?.smtp &&
            updateConfig({
              smtp: {...settings?.smtp},
              sender_email: settings?.sender_email,
              sender_name: settings?.sender_name,
            }),
        ]);

        toast.success('Settings has been updated successfully');
      } catch (error) {
        toast.error(error?.response?.data?.error);
      }
    }
  };

  return (
    <DashboardLayout className="mt-4" headerText="Company/Settings">
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-8">
            <div className="max-w-screen-2xl mx-auto sm:my-8">
              <div className="w-full flex items-center mb-12 border-b-2 border-b-borderGrey pb-2">
                <h5 className="w-full text-grey80 text-lg justify-between t  font-bold">
                  Settings
                </h5>

                <PrimaryButton
                  className="inline-flex justify-center w-[200px] text-white rounded text-tiny bg-brandBlue "
                  buttonText="Save changes"
                  loadingState={
                    updatingCompanyInfo || isUploading || updatingCompanyConfig
                  }
                  disabled={
                    Boolean(updatingCompanyInfo || updatingCompanyConfig) ||
                    isUploading
                  }
                  onClick={handleSaveChanges}
                />
              </div>
              <form
                className="grid grid-cols-1 mb-20 md:grid-cols-3 gap-x-8 lg:gap-x-16 w-full"
                onSubmit={e => e.preventDefault()}
              >
                <div>
                  <MainSettings setSettings={setSettings} settings={settings} />
                  <DashboardModularization
                    setSettings={setSettings}
                    settings={settings}
                  />
                </div>

                <div>
                  <SubDomainConfig
                    setSettings={setSettings}
                    settings={settings}
                  />
                </div>
                <div>
                  <ThemeConfig
                    setIsUploading={setIsUploading}
                    settings={settings}
                    setSettings={setSettings}
                  />
                </div>
              </form>
            </div>
          </div>
        </CompanyLayout>
      </LayoutContainer>
    </DashboardLayout>
  );
}

export default CompanySettings;
