import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {connect} from 'react-redux';
import * as Actions from '../store/actions';
import {closeModal} from '../assets/images/images';

function Example({
  children,
  show = false,
  onClose,
  extraModalClasses = '',
  modalBg = 'bg-white',
  modalPosition = 'items-center justify-center',
  modalPadding = 'p-6',
  modalTitle,
  enterFrom,
  leaveFrom,
  leaveTo,
  modalWidth = '480',
  noBottomPadding = false,
}) {
  const maxWidth = `max-w-[${modalWidth}px]`;
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10"
        onClose={() => {
          onClose();
        }}
      >
        <div
          className={`flex ${modalPosition} ${modalPadding} text-center h-full`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-black bg-opacity-25" />
          </Transition.Child>

          <span
            // className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            // className='-translate-y-40'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom={`${
              enterFrom ??
              'opacity-0 translate-y-20 sm:translate-y-0 sm:translate-x-20'
            }`}
            enterTo="opacity-100 translate-y-0 sm:translate-y-0 sm:translate-x-0 sm:scale-100"
            leave="ease-out duration-300"
            leaveFrom={`${
              leaveFrom ??
              'opacity-100 translate-y-0 sm:translate-x-0 sm:scale-100'
            }`}
            leaveTo={`${
              leaveTo ??
              'opacity-0 translate-y-20 sm:translate-y-0 sm:translate-x-20 sm:scale-95'
            }`}
          >
            <div
              className={`inline-block overflow-hidden w-full  ${modalBg} rounded-lg shadow-xl transition-all transform sm:p-0 sm:w-full ${extraModalClasses} ${maxWidth}`}
              style={{maxWidth: `${modalWidth}px`}}
            >
              {modalTitle ? (
                <div
                  className={`py-6 px-[19px] sm:px-6 ${
                    noBottomPadding ? 'sm:pt-12 sm:pb-0' : 'sm:py-12'
                  }`}
                >
                  <header className="flex items-center justify-between">
                    <h3 className="text-base font-medium text-grey">
                      {modalTitle}
                    </h3>
                    <button
                      onClick={() => {
                        onClose(false);
                      }}
                    >
                      <img src={closeModal} alt="" width={24} height={24} />
                    </button>
                  </header>
                  {children}
                </div>
              ) : (
                children
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default connect(null, Actions)(Example);
